let Global = {
  language: "english",

  isMobile: false,

  country: null,

  sound: true,

  source: "Social",

  firstTimeMusicPlayed: false,

  U_ID: null,
  gameKey: null,

  startTheGame: false,

  URL_CREATE: "https://newsfeedsmartapp.com/Oreo_IPL/api/createUser.php",

  WEBSERVICE: "https://newsfeedsmartapp.com/Oreo_IPL/api/webservice.php",
  VALUE_UPDATER: "https://newsfeedsmartapp.com/Oreo_IPL/api/valueUpdator.php",
};
export { Global };
